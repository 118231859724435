import React, { useEffect, useRef, useState } from "react";
import { useGlobal } from "../../context/global-context";
import ButtonCreator from "idmax-button-creator"; // Correct import
import "idmax-button-creator/dist/idmax-button-creator.css"; // Correct CSS import

const CreateIDMaxButtons = ({ buttonText, apiEndpoint, apiKey }) => {
  const buttonContainerRef = useRef(null);
  const { globalState, setContextValue } = useGlobal();
  const [token, setToken] = useState("");
  const [email, setEmail] = useState("");
  const getProvidersForCountry = (countryCode) => {
    switch (countryCode) {
      case "UK":
        return ["one_id", "digital_id_connect"];
      case "CA":
        return ["clear", "plaid"];
      case "US":
        return ["clear", "plaid", "mdl_la"];
      case "IN":
        return ["digilocker"];
      default:
        return ["clear", "plaid"];
    }
  };

  const fetchToken = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/demo/dcams/generate-link.php`,
        {
          method: "POST",

          body: JSON.stringify({
            journey: globalState.journey,
            age: globalState.age,
            choices: globalState.modularChoices,
            state: globalState,
          }),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.token && data.email) {
        setToken(data.token);
        setEmail(data.email);
      }
    } catch (error) {
      throw new Error("Failed to fetch the token");
    }
  };

  useEffect(() => {
    fetchToken();
  }, []);

  useEffect(() => {
    if (globalState.idmaxCountry && token && email) {
      const providers = getProvidersForCountry(globalState.idmaxCountry);
      new ButtonCreator({
        targetElement: buttonContainerRef.current,
        token: token,
        target: { email: email },
        method: "popup",
        providers: providers,
        resultMessages: {
          success: {
            title: "Test",
            subtitle: "Test",
          },
          failure: {
            title: "true",
            subtitle: "",
          },
          error: {
            title: "",
            subtitle: "",
          },
        },
        settings: {
          providers: {
            digital_id_connect: {
              showWorksWith: false,
            },
          },
        },
        styles: {
          buttons: {
            radius: "",
            gap: "",
          },
          modal: {
            overlay: {
              color: "",
              backgroundOpacity: "",
              blur: "",
            },
            content: {
              borderRadius: "",
            },
          },
        },
        onInit: (data) => {
          console.log("Init: ", data);
        },
        onClose: () => {
          console.log("The modal was closed by the user");
        },
        onComplete: (data) => {
          console.log("Done: ", data);
          //close();
          const path = `/result/PASS?email=${encodeURIComponent(email)}`;
          setContextValue("selectedClientApp", path);
        },
        onError: (error) => {
          console.error("Error: ", error.message);
        },
      });
    }
  }, [globalState.idmaxCountry, token, email]);

  return <div ref={buttonContainerRef}></div>;
};

export default CreateIDMaxButtons;
